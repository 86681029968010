<script setup lang="ts">
import type { Asset } from 'hydra-node';
import EasyToken from '~/components/easy/molecules/EasyToken.vue';
import { useCurrencyStore } from '~/stores/shared/currency';
import PriceRangeInput from '~/components/pro/molecules/Form/PriceRangeInput.vue';
import { ORDER_TYPES } from '~/enums/orders';

interface Props {
  fromAsset: Asset;
  fromAmount: number;
  toAsset: Asset;
  toAmount: number;
  fromPrice: number;
  toPrice: number;
  currentPrice: number;
  orderType?: ORDER_TYPES;
}

defineProps<Props>();

defineEmits<{
  close: [];
  confirm: [];
}>();

const { resolveFiatValue } = useCurrencyStore();
</script>

<template>
  <UCard class="flex flex-1 flex-col justify-between" :ui="{ body: { base: 'flex-1' }}">
    <!-- Header Slot -->
    <template #header>
      <div class="flex items-center justify-between">
        <div class="flex items-center">
          <UButton
            color="gray"
            variant="ghost"
            icon="i-heroicons-arrow-left"
            size="lg"
            class="-my-1"
            @click="$emit('close')"
          />
          <span class="text-gray-primary text-xl font-medium">
            {{ $t('liquidity-page.modal.header.title') }}
          </span>
        </div>
        <slot name="right" />
      </div>
    </template>

    <!-- Main Body Slot -->
    <div class="flex-1 overflow-y-auto">
      <slot name="body">
        <div class="flex flex-col gap-4">
          <EasyToken
            v-show="orderType === ORDER_TYPES.SELL || orderType === ORDER_TYPES.BOTH"
            :name="fromAsset?.name"
            :symbol="fromAsset?.symbol"
            :logo-url="fromAsset?.logoUrl"
            :network="fromAsset?.network"
            :token-value="fromAmount"
            :fiat-value="resolveFiatValue(fromAmount, fromAsset)"
            size="lg"
          />
          <EasyToken
            v-show="orderType === ORDER_TYPES.BUY || orderType === ORDER_TYPES.BOTH"
            :name="toAsset?.name"
            :symbol="toAsset?.symbol"
            :logo-url="toAsset?.logoUrl"
            :network="toAsset?.network"
            :token-value="toAmount"
            :fiat-value="resolveFiatValue(toAmount, toAsset)"
            size="lg"
          />
          <UDivider orientation="horizontal" />
          <div class="flex justify-between">
            <span class="text-gray-primary text-xl font-medium">
              {{ $t('liquidity-page.modal.selected-range.title') }}
            </span>
            <span class="text-gray-primary text-xl font-medium">
              {{ fromAsset?.symbol }} {{ toAsset?.symbol }}
            </span>
          </div>
          <UDivider orientation="horizontal" />
          <div class="my-4 flex items-center justify-between gap-x-4">
            <PriceRangeInput
              :display-value="fromPrice"
              disabled
              align="center"
              :title="$t('liquidity-page.range-input.minimum-price.title')"
              :subtitle="$t('liquidity-page.range-input.subtitle', {quote: fromAsset?.symbol, base: toAsset?.symbol})"
            />
            <UIcon name="i-heroicons-arrows-right-left" class="text-gray-primary size-8" />
            <PriceRangeInput
              :display-value="toPrice"
              disabled
              align="center"
              :title="$t('liquidity-page.range-input.maximum-price.title')"
              :subtitle="$t('liquidity-page.range-input.subtitle', {quote: fromAsset?.symbol, base: toAsset?.symbol})"
            />
          </div>
          <PriceRangeInput
            :display-value="currentPrice"
            :title="$t('liquidity-page.range-input.current-price.title')"
            align="center"
            disabled
            :subtitle="$t('liquidity-page.range-input.subtitle', {quote: fromAsset?.symbol, base: toAsset?.symbol})"
          />
        </div>
      </slot>
    </div>
    <!-- Footer Slot -->
    <template #footer>
      <slot name="footer">
        <div class="flex flex-col gap-4">
          <UButton
            size="lg"
            block
            class="capitalize"
            @click="$emit('confirm')"
          >
            {{ $t('generics.button.confirm-liquidity') }}
          </UButton>
          <UButton
            color="white"
            size="lg"
            block
            class="capitalize"
            @click="$emit('close')"
          >
            {{ $t('generics.button.go-back') }}
          </UButton>
        </div>
      </slot>
    </template>
  </UCard>
</template>
