import { defineStore } from 'pinia';
import { ref, reactive, computed, watch } from 'vue';
import isNil from 'lodash/isNil';
import type { z } from 'zod';

import { useWalletStore } from '~/stores/shared/wallet';
import { LiquiditySteps, PiniaStoresId, StepStatus } from '~/enums';
import CreateLiquidityModal from '~/components/pro/templates/Modals/CreateLiquidityModal.vue';
import type { MappedAssetBalance } from '~/types/asset';
import { Amount, createOrder, getAllOrders, getInitializedPairs, getOrderbook, initPair, Order } from 'hydra-node';
import type { Step } from '~/types';
import type { MappedOrder } from '~/types/orders';
import { ORDER_TYPES } from '~/enums/orders';
import type { LiquidityZodContext } from '~/types/ZSchemas/swap';
import { LiquidityAmountSchema } from '~/constants/ZSchemas/orders';

export const useLiquidityStore = defineStore(PiniaStoresId.LiquidityStore, () => {
  const isCreateLiquidityModalOpen = ref(false);
  const modal = useModal();
  const walletStore = useWalletStore();
  const toast = useToast();
  const alertBuilder = useAlertBuilder();
  const { t } = useI18n();

  const { assetsBalances } = storeToRefs(walletStore);

  const orders = ref<MappedOrder[]>([]);
  const lastFetched = ref<number>(0);
  const secondLiquidityAssetBalancesOptions = computed(() => {
    const selectedFrom = formState.selectedBaseAssetBalance;
    return selectedFrom
      ? assetsBalances.value?.filter(asset => asset.asset.name !== selectedFrom.asset.name)
      : assetsBalances.value;
  });
  // Form state
  const formState = reactive({
    selectedBaseAssetBalance: null as MappedAssetBalance | null,
    selectedQuoteAssetBalance: null as MappedAssetBalance | null,
    selectedFromPrice: 0,
    selectedToPrice: 0,
    selectedBaseAmount: 0,
    selectedQuoteAmount: 0,
    currentPrice: 0
  });

  const isLoading = ref<boolean>(false);

  // Determine order type
  const orderType = computed(() => {
    const { selectedFromPrice, currentPrice, selectedToPrice } = formState;
    if (selectedFromPrice <= currentPrice && currentPrice <= selectedToPrice) {
      return ORDER_TYPES.BOTH; // Both sell and buy liquidity
    }
    if (selectedFromPrice >= currentPrice) {
      return ORDER_TYPES.SELL; // Only sell liquidity
    }
    if (selectedToPrice <= currentPrice) {
      return ORDER_TYPES.BUY; // Only buy liquidity
    }
    return ORDER_TYPES.NONE; // No valid liquidity scenario
  });

  const watchedValues = computed(() => ({
    fromAssetBalance: formState.selectedBaseAssetBalance,
    toAssetBalance: formState.selectedQuoteAssetBalance
  }));

  const computedSchemaObject = computed(() => {
    const currentContext : LiquidityZodContext = {
      baseAssetBalance: formState.selectedBaseAssetBalance,
      quoteAssetBalance: formState.selectedQuoteAssetBalance,
      price: formState.currentPrice,
      baseAmount: formState.selectedBaseAmount,
      quoteAmount: formState.selectedQuoteAmount,
      fromPrice: formState.selectedFromPrice,
      toPrice: formState.selectedToPrice,
      orderType: orderType.value,
      t
    };
    return LiquidityAmountSchema(currentContext);
  });

  // Asset selection logic
  function selectAssetBalances () {
    selectBaseAssetBalance(assetsBalances.value);
  }

  function selectBaseAssetBalance (newBalances: MappedAssetBalance[]) {
    formState.selectedBaseAssetBalance = findAsset(newBalances, formState.selectedBaseAssetBalance, 'ETH');
  }
  async function fetchOrders () {
    const _allOrders = await getAllOrders();

    if (!_allOrders) {
      return;
    }

    const _orders: MappedOrder[] = [];
    const orderbookCache = new Map<string, { price: number }>();

    // Iterate through all asset pairs and their order maps
    for (const [assetPair, orderMap] of _allOrders.entries()) {
      if (orderMap.size === 0) {
        continue; // Skip empty maps
      }

      const [assetOne, assetTwo] = assetPair;
      const cacheKey = `${assetOne.symbol}-${assetTwo.symbol}`;

      // Fetch orderbook data for the asset pair
      let orderbook = orderbookCache.get(cacheKey);
      if (!orderbook) {
        const fetchedOrderbook = await getOrderbook(assetOne, assetTwo);
        const _currentPrice = fetchedOrderbook?.price.asFloat() || 0;
        orderbook = { price: _currentPrice };
        orderbookCache.set(cacheKey, orderbook);
      }

      const currentPrice = orderbook.price;

      // Process orders for this asset pair
      for (const [orderId, order] of orderMap.entries()) {
        // Calculate min and max price for the order
        const minPrice = order.minPrice?.asFloat() ?? currentPrice;
        const maxPrice = order.maxPrice?.asFloat() ?? currentPrice;

        // Add to the mapped orders array
        _orders.push({
          assetOne,
          assetTwo,
          orderId: orderId as string,
          order,
          inRange: currentPrice >= minPrice && currentPrice <= maxPrice,
          fromPrice: minPrice,
          toPrice: maxPrice
        });
      }
    }

    // Update orders and timestamp
    orders.value = _orders;
    lastFetched.value = Date.now();
  }

  function findAsset (balances: MappedAssetBalance[], currentBalance: MappedAssetBalance | null, defaultSymbol: string) {
    return (
      balances.find(balance =>
        currentBalance
          ? balance.asset.symbol === currentBalance.asset.symbol &&
            balance.asset.network.symbol === currentBalance.asset.network.symbol
          : balance.asset.symbol === defaultSymbol
      ) || null
    );
  }

  function openCreateLiquidityModal () {
    modal.open(CreateLiquidityModal);
    isCreateLiquidityModalOpen.value = true;
  }

  function closeCreateLiquidityModal () {
    modal.close();
    isCreateLiquidityModalOpen.value = false;
    resetLiquidityModal();
  };

  function resetLiquidityModal () {
    formState.selectedBaseAssetBalance = null;
    formState.selectedQuoteAssetBalance = null;
    formState.selectedFromPrice = 0;
    formState.selectedToPrice = 0;
    formState.selectedBaseAmount = 0;
    formState.selectedQuoteAmount = 0;
    formState.currentPrice = 0;
    resetSteps(allSteps);
  };

  // Place order
  async function setOrder () {
    const { selectedBaseAssetBalance, selectedQuoteAssetBalance, selectedBaseAmount, selectedQuoteAmount, selectedToPrice, selectedFromPrice } = formState;

    if (!selectedBaseAssetBalance || !selectedQuoteAssetBalance) {
      toast.add(alertBuilder.error({
        title: t('liquidity.order.errors.fetch.title'),
        description: t('liquidity.order.errors.fetch.description')
      }));
      return;
    }

    if (selectedBaseAssetBalance.offchain_balance.free_local.asFloat() <= 0) {
      toast.add(alertBuilder.error({
        title: t('liquidity.order.errors.offchain-balance.title'),
        description: t('liquidity.order.errors.offchain-balance.description')
      }));
      return;
    }

    isLoading.value = true;

    try {
      if (orderType.value === ORDER_TYPES.BOTH) {
        await createSellAndBuyOrders(selectedBaseAssetBalance, selectedQuoteAssetBalance, selectedBaseAmount, selectedQuoteAmount, selectedFromPrice, selectedToPrice);
      } else if (orderType.value === ORDER_TYPES.BUY) {
        await createBuyOrder(selectedBaseAssetBalance, selectedQuoteAssetBalance, selectedQuoteAmount, selectedFromPrice, selectedToPrice);
      } else if (orderType.value === ORDER_TYPES.SELL) {
        await createSellOrder(selectedBaseAssetBalance, selectedQuoteAssetBalance, selectedBaseAmount, selectedFromPrice, selectedToPrice);
      }
      toast.add(alertBuilder.success({
        title: t('liquidity.order.success.liquidity.title'),
        description: t('liquidity.order.success.liquidity.description')
      }));
      closeCreateLiquidityModal();
    } catch (error) {
      toast.add(alertBuilder.error({
        title: t('liquidity.order.errors.liquidity.title'),
        description: t('liquidity.order.errors.liquidity.description')
      }));
    } finally {
      isLoading.value = false;
    }
  }

  async function createSellAndBuyOrders (baseAsset: MappedAssetBalance, quoteAsset: MappedAssetBalance, baseAmount: number, quoteAmount: number, fromPrice: number, toPrice:number) {
    const sellOrder = Order.newAddSellLiquidity(Amount.fromFloat(baseAmount), undefined, Amount.fromFloat(toPrice), false);
    await createOrder(baseAsset.asset, quoteAsset.asset, sellOrder);

    const buyOrder = Order.newAddBuyLiquidity(Amount.fromFloat(quoteAmount), Amount.fromFloat(fromPrice), undefined, false);
    await createOrder(baseAsset.asset, quoteAsset.asset, buyOrder);
  }

  async function createBuyOrder (baseAsset: MappedAssetBalance, quoteAsset: MappedAssetBalance, quoteAmount: number, fromPrice: number, toPrice:number) {
    const buyOrder = Order.newAddBuyLiquidity(Amount.fromFloat(quoteAmount), Amount.fromFloat(fromPrice), Amount.fromFloat(toPrice), false);
    await createOrder(baseAsset.asset, quoteAsset.asset, buyOrder);
  }

  async function createSellOrder (baseAsset: MappedAssetBalance, quoteAsset: MappedAssetBalance, baseAmount: number, fromPrice: number, toPrice:number) {
    const sellOrder = Order.newAddSellLiquidity(Amount.fromFloat(baseAmount), Amount.fromFloat(fromPrice), Amount.fromFloat(toPrice), false);
    await createOrder(baseAsset.asset, quoteAsset.asset, sellOrder);
  }

  /**
     * Steps
     */
  const allSteps = reactive<Record<LiquiditySteps, Step>>({
    [LiquiditySteps.ASSETS]: {
      id: LiquiditySteps.ASSETS,
      name: t('liquidity-page.steps.assets.title'),
      status: StepStatus.Current,
      isFirst: true
    },
    [LiquiditySteps.RANGE]: {
      id: LiquiditySteps.RANGE,
      name: t('liquidity-page.steps.range.title'),
      status: StepStatus.Upcoming
    },
    [LiquiditySteps.AMOUNT]: {
      id: LiquiditySteps.AMOUNT,
      name: t('liquidity-page.steps.amount.title'),
      status: StepStatus.Upcoming,
      isLast: true
    }
  });

  const steps = computed<Step[]>(() => {
    const _steps = [allSteps.ASSETS, allSteps.RANGE];

    return [..._steps, allSteps.AMOUNT];
  });

  const currentStep = computed<Step>(() => {
    return steps.value.find(step => step.status === StepStatus.Current) || steps.value[0];
  });

  const currentStepIndex = computed<number>(() => {
    return (
      steps.value.findIndex(step => step.status === StepStatus.Current) || 0
    );
  });

  /**
     * Navigation
     */
  const canGoNext = computed(() => {
    //
    const currentStepId = currentStep.value?.id;
    if (currentStepId === LiquiditySteps.ASSETS) {
      return (!isNil(formState.selectedBaseAssetBalance) && !isNil(formState.selectedQuoteAssetBalance));
    } else if (currentStepId === LiquiditySteps.RANGE) {
      return (
        formState.selectedFromPrice > 0 &&
        formState.selectedToPrice > 0 &&
        formState.selectedToPrice > formState.selectedFromPrice
      );
    } else if (currentStepId === LiquiditySteps.AMOUNT) {
      const { success, errors } = _validateForm();
      console.debug('success', success, errors);
      const isSuccess = success && !errors;
      return isSuccess;
    }
    return false;
  });

  function _validateForm () {
    return validateForm<z.infer<typeof computedSchemaObject.value>>(
      formState,
      computedSchemaObject.value,
      t
    );
  }

  function onNext () {
    if (!canGoNext.value) {
      return;
    }
    const currentStepId = currentStep.value?.id;
    if (currentStepId === LiquiditySteps.ASSETS) {
      allSteps[currentStepId].status = StepStatus.Complete;
      allSteps[LiquiditySteps.RANGE].status = StepStatus.Current;
    }
    if (currentStepId === LiquiditySteps.RANGE) {
      allSteps[currentStepId].status = StepStatus.Complete;
      allSteps[LiquiditySteps.AMOUNT].status = StepStatus.Current;
    }
    return true;
  }

  function onBack () {
    const currentStepId = currentStep.value.id;

    if (currentStepId === LiquiditySteps.RANGE) {
      allSteps.RANGE.status = StepStatus.Upcoming;
      allSteps.ASSETS.status = StepStatus.Current;
    } else if (currentStepId === LiquiditySteps.AMOUNT) {
      allSteps.AMOUNT.status = StepStatus.Upcoming;
      allSteps.RANGE.status = StepStatus.Current;
    }
  }

  watch(assetsBalances, selectAssetBalances, { immediate: true, deep: true });

  // Watchers
  watch(
    () => [watchedValues.value.fromAssetBalance, watchedValues.value.toAssetBalance],
    async ([fromAsset, toAsset]) => {
      if (!fromAsset || !toAsset) {
        return;
      }

      try {
        const pairs = await getInitializedPairs();
        const pairExists = pairs.some(([c1, c2]) =>
          (c1.id === fromAsset.asset.id && c2.id === toAsset.asset.id) ||
          (c1.id === toAsset.asset.id && c2.id === fromAsset.asset.id)
        );

        if (!pairExists) {
          await initPair(toAsset.asset, fromAsset.asset);
        }
        const orderbook = await getOrderbook(fromAsset.asset, toAsset.asset);
        formState.currentPrice = orderbook?.price.asFloat() || 0;
      } catch (error) {
        toast.add(alertBuilder.error({
          title: t('liquidity.order.errors.init-pair.title'),
          description: t('liquidity.order.errors.init-pair.description')
        }));
        console.error('Error initializing pairs:', error);
      }
    }, { deep: true });

  function adjustAmounts (baseAmountChanged: boolean) {
    console.debug('i am adjusting');
    const { selectedFromPrice, selectedToPrice, currentPrice, selectedBaseAmount, selectedQuoteAmount } = formState;

    if (baseAmountChanged) {
      console.debug('adjusting baseAMount 1');
      if (selectedToPrice > currentPrice && selectedBaseAmount > 0) {
        console.debug('adjusting baseAMount 2');
        const averagePrice = (currentPrice + selectedToPrice) / 2;
        const fittedQuoteAmount = selectedBaseAmount * averagePrice;
        const buyAmount = Math.abs((fittedQuoteAmount / (selectedToPrice - currentPrice)) * (currentPrice - selectedFromPrice));
        console.debug('adjusting baseAMount 3', buyAmount);
        formState.selectedQuoteAmount = buyAmount;
      }
    } else if (selectedFromPrice < currentPrice && selectedQuoteAmount > 0) {
      console.debug('adjusting quoteAmount 1');
      const priceDifference = selectedToPrice - currentPrice;
      const fittedBaseAmount = selectedQuoteAmount * priceDifference / (currentPrice - selectedFromPrice);
      const sellAmount = Math.abs(fittedBaseAmount / ((currentPrice + selectedToPrice) / 2));
      console.debug('adjusting quoteAmount 2', sellAmount);
      formState.selectedBaseAmount = sellAmount;
    }
  }

  return {
    steps,
    onBack,
    onNext,
    canGoNext,
    currentStep,
    openCreateLiquidityModal,
    isCreateLiquidityModalOpen,
    closeCreateLiquidityModal,
    formState,
    secondLiquidityAssetBalancesOptions,
    assetsBalances,
    setOrder,
    fetchOrders,
    currentStepIndex,
    orders,
    adjustAmounts,
    resetLiquidityModal,
    orderType,
    computedSchemaObject,
    lastFetched
  };
});
