<script setup lang="ts">
import type { AssetBalance } from 'hydra-node';
import EasyToken from '~/components/easy/molecules/EasyToken.vue';
import { useCurrencyStore } from '~/stores/shared/currency';

interface Props {
  assetBalances: AssetBalance[],
};

defineProps<Props>();

const { resolveFiatValue } = useCurrencyStore();

const emit = defineEmits<{
  'change:value': [value: AssetBalance]
}>();

function emitSelectedAssetBalanceOption (value: AssetBalance) {
  emit('change:value', value as AssetBalance);
}

function assetBalanceValues (assetBalance: AssetBalance) {
  const cryptoValue = assetBalance.offchain_balance.free_local.asFloat() + assetBalance.onchain_balance.usable.asFloat();
  return {
    fiatValue: resolveFiatValue(cryptoValue, assetBalance.asset),
    tokenValue: cryptoValue
  };
}
</script>

<template>
  <UButton
    v-for="(assetBalance, index) in assetBalances"
    :key="index"
    variant="ghost"
    class="flex w-full flex-col"
    @click="emitSelectedAssetBalanceOption(assetBalance)"
  >
    <EasyToken
      :logo-url="assetBalance.asset.logoUrl"
      :name="assetBalance.asset.name"
      :network="assetBalance.asset.network"
      :symbol="assetBalance.asset.symbol"
      size="md"
      :fiat-value="assetBalanceValues(assetBalance).fiatValue"
      :token-value="assetBalanceValues(assetBalance).tokenValue"
    />
  </UButton>
</template>
