import { z } from 'zod';
import type { AssetBalance } from '~/hydra-node/hydra_node';
import type { SwapZodContext } from '~/types/ZSchemas/swap';
import { hasEnoughAvailableOffChainBalance, hasEnoughReceivingOffChainBalance } from '~/utils';

const isAssetBalanceSet = (assetBalance: AssetBalance | null): boolean => {
  return assetBalance !== null;
};

/**
 * We use the superRefine method to chain custom validation and
 * prevent further validation if one of them fails.
 * @param context
 * @constructor
 */
export const SwapSchema = (context: SwapZodContext) =>
  z.object({
    selectedFromAssetBalance: z.custom((value) => {
      return isAssetBalanceSet(toValue(value));
    }, {
      message: context.t('form-schemas.errors.rental.required_error')
    }),
    selectedToAssetBalance: z.custom((value) => {
      return isAssetBalanceSet(value);
    }, {
      message: context.t('form-schemas.errors.rental.required_error')
    }),
    selectedFromAmount: z.number({
      invalid_type_error: context.t('form-schemas.errors.amount.invalid_type_error'),
      required_error: context.t('form-schemas.errors.amount.required_error')
    })
      .positive({
        message: context.t('form-schemas.errors.amount.positive_error')
      })
      .finite()
      .refine(val => hasEnoughAvailableOffChainBalance(context.fromAsset!, val), {
        message: context.t('generics.not-enough-balance')
      }),
    selectedToAmount: z.number({
      invalid_type_error: context.t('form-schemas.errors.amount.invalid_type_error'),
      required_error: context.t('form-schemas.errors.amount.required_error')
    })
      .positive({
        message: context.t('form-schemas.errors.amount.positive_error')
      })
      .finite()
      .refine(val => hasEnoughReceivingOffChainBalance(context.toAsset!, val), {
        message: context.t('generics.not-enough-receiving-balance')
      })
  });
