<script lang="ts" setup generic="T">

import { ref } from 'vue';
import { type EventBusKey, useEventBus } from '@vueuse/core';
import type { AlertVariant } from '#ui/types';
import type { Alert } from '~/types';

interface Props {
  eventBusKey: Symbol
  multiple?: boolean
  variant?: AlertVariant
}
const props = withDefaults(defineProps<Props>(), {
  variant: 'soft'
});
const alerts = ref<Alert[]>([]);

const bus = useEventBus(props.eventBusKey as EventBusKey<Alert>);

bus.on((payload) => {
  if (props.multiple) {
    alerts.value.unshift({
      id: Date.now(),
      ...payload
    });
    return;
  }
  alerts.value = [{
    id: Date.now(),
    ...payload
  }];
});

function onClose (id:number) {
  alerts.value = alerts.value.filter(alert => alert.id !== id);
}
</script>
<template>
  <section v-show="alerts.length > 0" class="space-y-4">
    <TransitionGroup
      enter-active-class="delay-300 duration-300 ease-out"
      enter-from-class="transform opacity-0 translate-y-full"
      enter-to-class="opacity-100 translate-y-0"
      leave-active-class="duration-200 ease-in"
      leave-from-class="opacity-100 translate-y-0"
      leave-to-class="transform opacity-0 translate-y-full"
    >
      <UAlert
        v-for="alert in alerts"
        :key="alert.id"
        :variant="variant"
        v-bind="alert"
        @close="() => onClose(alert.id!)"
      />
    </TransitionGroup>
  </section>
</template>
