<script setup lang="ts">
import type { NetworkSelectionOption } from '~/types/filters';
import EasyNetwork from '~/components/easy/molecules/EasyNetwork.vue';

interface Props {
  networks: NetworkSelectionOption[],
  isSelected: Function
};

const props = defineProps<Props>();

const emit = defineEmits<{
  'change:value': [value: string]
  'change:option': [option: NetworkSelectionOption]
}>();

function emitSelectedOption (value: string) {
  emit('change:value', value);
  emit('change:option', props.networks.find(o => o.value === value)!);
}

const selected = defineModel<string>('');

</script>

<template>
  <URadioGroup
    v-model="selected"
    :options="networks"
    :ui="{
      wrapper: 'justify-end',
      fieldset:'w-full space-y-4'
    }"
    :ui-radio="{
      wrapper:'flex-row-reverse items-center justify-between',
      inner: 'ms-0 me-3 grow'
    }"
    @change="emitSelectedOption"
  >
    <template #label="{ option }">
      <EasyNetwork v-bind="option" size="lg" />
    </template>
  </URadioGroup>
</template>
