<script lang="ts" setup>
import AllNetworkIcon from '../atoms/Icons/AllNetworkIcon.vue';

interface Props {
  label: string;
  logoUrl?: string;
  symbol: string;
  size?: 'sm' | 'md' | 'lg';
}

const props = withDefaults(defineProps<Props>(), {
  size: 'sm'
});

const getAvatarSize = (() => {
  return {
    sm: 'xs',
    md: 'sm',
    lg: 'md'
  }[props.size];
})();
</script>

<template>
  <div class="flex w-full items-center justify-between">
    <div class="flex items-center gap-x-2">
      <slot name="avatar">
        <div class="relative flex items-center">
          <UAvatar
            v-if="logoUrl"
            :src="logoUrl"
            :text="symbol"
            :size="getAvatarSize"
            class="u-bg-gray-200 relative"
          />
          <UAvatar
            v-else
            :text="symbol"
            :size="getAvatarSize"
            class="u-bg-gray-200 relative"
          >
            <AllNetworkIcon />
          </UAvatar>
        </div>
      </slot>
      <dl>
        <div class="flex items-center justify-start" :class="size === 'sm' ? 'gap-x-1' : 'gap-x-2'">
          <dd
            class="text-gray-tertiary font-normal capitalize"
            :class="size === 'sm' ? 'text-sm' : 'text-base'"
          >
            {{ label }}
          </dd>
        </div>
      </dl>
    </div>
  </div>
</template>
