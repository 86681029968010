import type { MaybeRef } from 'vue';
import type { Step } from '~/types';
import { StepStatus } from '~/enums';

export function findNextStep (steps: MaybeRef<Step[]>, currentStepIndex: MaybeRef<number>) {
  return toValue(steps)[(toValue(currentStepIndex) + 1) as keyof typeof steps];
}

export function findPreviousStep (steps: MaybeRef<Step[]>, currentStepIndex: MaybeRef<number>) {
  return toValue(steps)[(toValue(currentStepIndex) - 1) as keyof typeof steps];
}

export function resetSteps (steps: any) {
  for (const key in steps) {
    if (Object.prototype.hasOwnProperty.call(steps, key)) {
      const step = steps[key];
      step.status = step.isFirst ? StepStatus.Current : StepStatus.Upcoming;
    }
  }
  return steps;
}
