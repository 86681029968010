<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useLiquidityStore } from '~/stores/pro/liquidity';
import CryptoAssetSelectMenu from '~/components/pro/atoms/SelectOptions/CryptoAssetSelectMenu.vue';
import PriceRangeInput from '~/components/pro/molecules/Form/PriceRangeInput.vue';
import CryptoSelectMenu from '~/components/pro/atoms/SelectOptions/CryptoSelectMenu.vue';
import { useCurrencyStore } from '~/stores/shared/currency';
import { LiquiditySteps } from '~/enums';
import { ORDER_TYPES } from '~/enums/orders';

const liquidityStore = useLiquidityStore();
const { formState, assetsBalances, secondLiquidityAssetBalancesOptions, currentStep, orderType, computedSchemaObject } = storeToRefs(liquidityStore);
const { resolveFiatValue } = useCurrencyStore();

const isOutOfRange = computed(() =>
  formState.value.selectedFromPrice > formState.value.currentPrice ||
  formState.value.selectedToPrice < formState.value.currentPrice
);

// Generate subtitles for price inputs
const generatePriceSubtitle = (quoteSymbol?: string, baseSymbol?: string) =>
  `${quoteSymbol} per ${baseSymbol}`;
</script>

<template>
  <!-- STEP 1: ASSETS -->
  <div v-if="currentStep.id === LiquiditySteps.ASSETS">
    <div class="flex flex-col pb-5">
      <span class="text-gray-primary text-lg font-medium">
        {{ $t('liquidity-page.steps.cards.pairs.title') }}
      </span>
      <span class="text-gray-primary text-base">
        {{ $t('liquidity-page.steps.cards.pairs.description') }}
      </span>
    </div>
    <div class="flex gap-x-4">
      <CryptoAssetSelectMenu
        v-model:balance="formState.selectedBaseAssetBalance!"
        size="md"
        :options="assetsBalances"
        class="w-1/2"
      />
      <CryptoAssetSelectMenu
        v-model:balance="formState.selectedQuoteAssetBalance!"
        size="md"
        :options="secondLiquidityAssetBalancesOptions"
        class="w-1/2"
      />
    </div>
  </div>

  <!-- STEP 2: RANGE -->
  <UForm v-if="currentStep.id === LiquiditySteps.RANGE" :state="formState">
    <div class="flex flex-col pb-5">
      <span class="text-gray-primary text-lg font-medium">
        {{ $t('liquidity-page.steps.cards.range.title') }}
      </span>
      <span class="text-gray-primary text-base">
        {{ $t('liquidity-page.steps.cards.range.description') }}
      </span>
    </div>
    <UAlert
      v-if="isOutOfRange"
      class="my-4"
      variant="subtle"
      icon="i-heroicons-exclamation-circle"
      color="yellow"
      :title="$t('liquidity-page.modal.alert.range.title')"
      :description="$t('liquidity-page.modal.alert.range.description')"
    />
    <div class="my-4 flex items-center justify-between gap-x-4">
      <PriceRangeInput
        v-model="formState.selectedFromPrice"
        :title="$t('liquidity-page.range-input.minimum-price.title')"
        :subtitle="generatePriceSubtitle(formState.selectedQuoteAssetBalance?.asset.symbol, formState.selectedBaseAssetBalance?.asset.symbol)"
      />
      <UIcon name="i-heroicons-arrows-right-left" class="text-gray-primary size-8" />
      <PriceRangeInput
        v-model="formState.selectedToPrice"
        :title="$t('liquidity-page.range-input.maximum-price.title')"
        :subtitle="generatePriceSubtitle(formState.selectedQuoteAssetBalance?.asset.symbol, formState.selectedBaseAssetBalance?.asset.symbol)"
      />
    </div>
    <PriceRangeInput
      v-model="formState.currentPrice"
      :title="$t('liquidity-page.range-input.current-price.title')"
      align="center"
      disabled
      :subtitle="generatePriceSubtitle(formState.selectedQuoteAssetBalance?.asset.symbol, formState.selectedBaseAssetBalance?.asset.symbol)"
    />
  </UForm>

  <!-- STEP 3: AMOUNT -->
  <UForm v-if="currentStep.id === LiquiditySteps.AMOUNT" :state="formState" :schema="computedSchemaObject">
    <div class="flex flex-col pb-5">
      <span class="text-gray-primary text-lg font-medium">
        {{ $t('liquidity-page.steps.cards.amount.title') }}
      </span>
      <span class="text-gray-primary text-base">
        {{ $t('liquidity-page.steps.cards.amount.description') }}
      </span>
    </div>
    <div class="flex flex-col items-center gap-y-4">
      <!-- Show Base Asset SelectMenu if Sell Liquidity Order -->
      <UFormGroup v-if="orderType === ORDER_TYPES.SELL || orderType === ORDER_TYPES.BOTH" name="selectedBaseAmount" class="lg:w-3/4" eager-validation>
        <CryptoSelectMenu
          v-model:amount="formState.selectedBaseAmount"
          v-model:balance="formState.selectedBaseAssetBalance!"
          :fiat-amount="resolveFiatValue(formState.selectedBaseAmount, formState.selectedBaseAssetBalance?.asset!)"
          size="md"
          @change="liquidityStore.adjustAmounts(true)"
        />
      </UFormGroup>
      <!-- Show Quote Asset SelectMenu if Buy Liquidity Order -->
      <UFormGroup v-if="orderType === ORDER_TYPES.BUY || orderType === ORDER_TYPES.BOTH" name="selectedQuoteAmount" class="lg:w-3/4" eager-validation>
        <CryptoSelectMenu
          v-model:amount="formState.selectedQuoteAmount"
          v-model:balance="formState.selectedQuoteAssetBalance!"
          :fiat-amount="resolveFiatValue(formState.selectedQuoteAmount, formState.selectedQuoteAssetBalance?.asset!)"
          size="md"
          @change="liquidityStore.adjustAmounts(false)"
        />
      </UFormGroup>
    </div>
  </UForm>
</template>
