import isUndefined from 'lodash/isUndefined';
import { Amount, Asset, AssetBalance } from 'hydra-node';
import { useSettingsStore } from '~/stores/shared/settings';
import { DATE_FORMATS } from '~/constants';

const DEFAULT_CRYPTO_DECIMALS = 8;

export function useIntlFormats () {
  const { n, d } = useI18n();
  const settingsStore = useSettingsStore();
  const {
    preferences,
    DEFAULT_TIMEZONE_UTC,
    DEFAULT_CURRENCY_USD
  } = useSettingsStore();

  function formatCrypto(
    amount: string | number | undefined,
    symbol: string,
    decimals?: number,
    options?: Intl.NumberFormatOptions,
    position?: 'end' | 'start'
  ): string;
  function formatCrypto(
    amount: string | number | Amount | undefined,
    assetBalance: AssetBalance,
    decimals?: number,
    options?: Intl.NumberFormatOptions,
    position?: 'end' | 'start'
  ): string;
  function formatCrypto(
    amount: string | number | Amount | undefined,
    asset: Asset,
    decimals?: number,
    options?: Intl.NumberFormatOptions,
    position?: 'end' | 'start'
  ): string;

  /**
   * Formats a crypto amount with the given symbol,asset or asset balance
   * Easier to use and less verbose
   * @example formatCrypto(balance.asFloat(), 'BTC')
   * @example formatCrypto(balance.asFloat(), asset)
   * @example formatCrypto(balance.asFloat(), assetBalance)
   * @param amount
   * @param symbolOrAssetOrAssetBalance
   * @param decimals
   * @param options
   * @param position
   */
  function formatCrypto (
    amount: string | number | Amount | undefined,
    symbolOrAssetOrAssetBalance: string | Asset | AssetBalance,
    decimals = DEFAULT_CRYPTO_DECIMALS,
    options = {},
    position = 'end'
  ): string {
    if (!symbolOrAssetOrAssetBalance) { return ''; };
    let symbol = symbolOrAssetOrAssetBalance;
    let _amount = 0;

    if (typeof symbolOrAssetOrAssetBalance === 'string') {
      symbol = symbolOrAssetOrAssetBalance;
    } else if ('symbol' in symbolOrAssetOrAssetBalance) {
      symbol = symbolOrAssetOrAssetBalance?.symbol;
    } else if ('asset' in symbolOrAssetOrAssetBalance) {
      symbol = symbolOrAssetOrAssetBalance?.asset?.symbol;
    }

    if (typeof amount === 'string') {
      _amount = parseFloat(amount) || 0;
    } else if (typeof amount === 'number') {
      _amount = amount;
    } else if (amount instanceof Amount) {
      _amount = isUndefined(amount.asFloat()) ? 0 : amount.asFloat();
    }
    let formattedAmount = '0.00';
    if (_amount !== 0) {
      formattedAmount =
        n(_amount, {
          style: 'decimal',
          // to avoid Intl error because minimumFractionDigits must be <= maximumFractionDigits
          minimumFractionDigits: decimals < 2 ? decimals : 2, // minimum number of decimals
          maximumFractionDigits: decimals, // maximum number of decimals,
          ...options
        });
    }

    const values = [formattedAmount, symbol];
    if (position === 'end') {
      return values.join(' ');
    }
    return values.reverse().join(' ');
  }

  function formatFiat (amount: number | string | undefined, options: Intl.NumberFormatOptions = {}): string {
    const currencyCode = options.currency || settingsStore?.preferences?.currency?.code || DEFAULT_CURRENCY_USD.code;
    const _amount = typeof amount === 'string' ? parseFloat(amount) : amount;

    const _formattedAmount = n(_amount || 0, {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      ...options
    });

    return `${_formattedAmount} ${currencyCode}`;
  }

  const formatWithTimezone = (
    dateValue: Date,
    formatKey: keyof typeof DATE_FORMATS
  ) => {
    const formatOptions = DATE_FORMATS[formatKey];
    const customOptions = {
      ...formatOptions,
      hour12: preferences.is12Hour,
      timeZone: preferences.timezone?.code || DEFAULT_TIMEZONE_UTC.code
    };
    const formattedValue = d(dateValue, customOptions);

    return formattedValue;
  };

  function formatPercentage (fraction: number, options: Intl.NumberFormatOptions = {}): string {
    if (fraction < 0 || fraction > 1) {
      console.error('Fraction must be between 0 and 1');
      return '0';
    }

    return n(fraction || 0, {
      style: 'percent',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      ...options
    });
  }

  return {
    formatCrypto,
    formatFiat,
    formatWithTimezone,
    formatPercentage
  };
}
