<script lang="ts" setup>
import type { Network } from 'hydra-node';
import { getNetworkName } from '~/utils';
import InputNumber from '~/components/shared/forms/InputNumber.vue';

interface Props {
  symbol?: string;
  network?: Network;
}

const props = defineProps<Props>();

const ui = computed(() => ({
  leading: props.symbol
    ? {
        padding: {
          lg: 'ps-14'
        }
      }
    : undefined,
  trailing: props.network
    ? {
        padding: {
          lg: 'pe-32'
        }
      }
    : undefined
}));

const inputNumber = ref<typeof InputNumber|null>(null);
// TODO check if can do without it
defineExpose({
  setValue: (value:string|number) => inputNumber.value?.setValue(value)
});
const networkName = computed(() => getNetworkName(props.network!));
</script>
<template>
  <InputNumber
    ref="inputNumber"
    data-testid="amount-input"
    :ui="ui"
  >
    <template
      v-if="symbol"
      #leading
    >
      <span class="u-text-primary-400 text-sm font-medium uppercase">
        {{ symbol }}
      </span>
    </template>
    <template
      v-if="network && !!networkName"
      #trailing
    >
      <UKbd
        class="uppercase"
        size="xs"
      >
        <span class="max-w-24 truncate ">
          {{ networkName }}
        </span>
      </UKbd>
    </template>
  </InputNumber>
</template>
