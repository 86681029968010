import routerOptions0 from "/opt/build/repo/node_modules/.pnpm/nuxt@3.15.0_@types+node@18.19.68_eslint@8.57.1_rollup@4.29.1_sass@1.83.0_stylelint@15.11.0_ty_ne5rlqmexdp2rvsgkwamgdodje/node_modules/nuxt/dist/pages/runtime/router.options.js";
import routerOptions1 from "/opt/build/repo/app/router.options.ts";
import routerOptions2 from "/opt/build/repo/node_modules/.pnpm/@nuxt+ui-pro@1.6.0_qrcode@1.5.4_rollup@4.29.1_typescript@5.7.2_vite@5.4.11_vue@3.5.13/node_modules/@nuxt/ui-pro/modules/pro/runtime/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export const hashMode = routerOptions1.hashMode ?? routerOptions0.hashMode ?? false
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
...routerOptions2,
}