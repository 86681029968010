import { z, ZodError, ZodSchema } from 'zod';
import type { ZodValidationResult } from '~/types/ZSchemas';
import type { Translator } from '~/types/Generics';

export function isZodError (error: any): error is ZodError {
  return error.issues !== undefined;
}
export function zodValidate<T> (
  values: T,
  schema: ZodSchema
): ZodValidationResult<T> {
  try {
    return {
      success: true,
      data: schema.parse(values),
      errors: null
    };
  } catch (error) {
    if (isZodError(error)) {
      const formattedErrors = error.issues.map(issue => ({
        path: issue.path.join('.'),
        message: issue.message
      }));
      return {
        success: false,
        data: null,
        errors: formattedErrors
      };
    } else {
      console.error('Fatal error validating form', error);
      throw error;
    }
  }
}

export function validateForm<T> (
  values: T,
  schemaObject: z.ZodSchema<T>,
  translator?: Translator
) {
  const validation = zodValidate<T>(values, schemaObject);
  if (translator) {
    return {
      ...validation,
      errors: validation.errors?.map(error => ({
        ...error,
        message: translator(error.message)
      }))
    };
  }
  return validation;
}
