<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import { useWindowSize } from '@vueuse/core';
import CreateLiquidity from '../Liquidity/CreateLiquidity.vue';
import WalletActionModal from '~/components/pro/templates/Modals/WalletActionModal.vue';
import { channelRentalActionAlertKey } from '~/constants';
import AlertBoxListener from '~/components/pro/molecules/AlertBoxListener.vue';
import ConfirmLiquidityCard from '~/components/easy/templates/Cards/ConfirmLiquidityCard.vue';

import StepButton from '~/components/pro/templates/WalletActions/StepButton.vue';
import { useLiquidityStore } from '~/stores/pro/liquidity';

import { Breakpoints } from '~/config';

const liquidityStore = useLiquidityStore();
const { width } = useWindowSize();

const isMobile = computed(() => width.value < Breakpoints.lg);

const { onBack, onNext } = liquidityStore;

const { isCreateLiquidityModalOpen, currentStep, canGoNext, formState, orderType } = storeToRefs(liquidityStore);

const isOpen = ref<boolean>(false);

function handleSetOrder () {
  isOpen.value = false;
  liquidityStore.setOrder();
}

const onClose = () => {
  isCreateLiquidityModalOpen.value = false;
  liquidityStore.closeCreateLiquidityModal();
};

</script>
<template>
  <WalletActionModal
    v-model="isCreateLiquidityModalOpen"
    :title="
      $t('liquidity-page.card.title')
    "
    @close="onClose"
  >
    <CreateLiquidity />
    <AlertBoxListener
      :event-bus-key="channelRentalActionAlertKey"
      class="my-6"
    />

    <UModal v-if="!isMobile" v-model="isOpen">
      <ConfirmLiquidityCard
        :from-asset="formState.selectedBaseAssetBalance?.asset"
        :from-amount="formState.selectedBaseAmount"
        :to-asset="formState.selectedQuoteAssetBalance?.asset"
        :to-amount="formState.selectedQuoteAmount"
        :from-price="formState.selectedFromPrice"
        :to-price="formState.selectedToPrice"
        :current-price="formState.currentPrice"
        :order-type="orderType"
        @close="isOpen = false"
        @confirm="handleSetOrder"
      />
    </UModal>
    <USlideover v-else v-model="isOpen" side="bottom" :ui="{height: 'h-screen max-h-144'}">
      <ConfirmLiquidityCard
        :from-asset="formState.selectedBaseAssetBalance?.asset"
        :from-amount="formState.selectedBaseAmount"
        :to-asset="formState.selectedQuoteAssetBalance?.asset"
        :to-amount="formState.selectedQuoteAmount"
        :from-price="formState.selectedFromPrice"
        :to-price="formState.selectedToPrice"
        :current-price="formState.currentPrice"
        :order-type="orderType"
        @close="isOpen = false"
        @confirm="handleSetOrder"
      />
    </USlideover>

    <template #footer>
      <div class="flex items-center justify-center gap-6">
        <StepButton
          v-show="currentStep?.isFirst"
          close
          @click="onClose"
        />
        <StepButton
          v-show="!currentStep?.isFirst"
          back
          @click="onBack"
        />
        <StepButton
          v-show="!currentStep?.isLast"
          :disabled="!canGoNext"
          next
          @click="onNext"
        />
        <StepButton
          v-show="currentStep?.isLast"
          :disabled="!canGoNext"
          label="Confirm"
          @click="isOpen = true"
        />
      </div>
    </template>
  </WalletActionModal>
</template>
