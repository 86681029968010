<script lang="ts" setup>
import InputNumber from '~/components/shared/forms/InputNumber.vue';

interface Props {
  symbol?: string;
  variant?: 'none' | 'outline';
  size?: 'sm' | 'md' | 'lg';
  align?: 'left' | 'right' | 'center';
}

const props = withDefaults(defineProps<Props>(), {
  align: 'right'
});

const emit = defineEmits(['changed']);

const ui = computed(() => ({
  base: `font-medium text-gray-primary ${props.align === 'left' ? 'text-left' : props.align === 'center' ? 'text-center' : 'text-right'}`,
  size: {
    lg: 'text-lg',
    sm: 'text-base',
    md: 'text-base'
  }
}));

const inputNumber = ref<typeof InputNumber|null>(null);

// TODO check if can do without it
defineExpose({
  setValue: (value:string|number) => inputNumber.value?.setValue(value)
});

function handleInputChange (value: number) {
  emit('changed', value);
}
</script>

<template>
  <InputNumber
    ref="inputNumber"
    data-testid="amount-input"
    :variant="variant"
    :ui="ui"
    class="text-gray-primary font-medium"
    @update:model-value="handleInputChange"
  />
</template>
