import type { z } from 'zod';
import { defineStore, storeToRefs } from 'pinia';
import {
  Amount, createOrder, DexPairEvent, estimateMarketOrder,
  getInitializedPairs, getMarketInfo, initPair,
  MarketOrderAmount, Order, OrderSide,
  SwapStatus, DexPairEventType,
  Asset
} from 'hydra-node';
import { AppMode, PiniaStoresId, SwapProgressState } from '~/enums';
import { useWalletStore } from '~/stores/shared/wallet';
import { useSettingsStore } from '~/stores/shared/settings';
import type { MappedAssetBalance } from '~/types/asset';
import type { SwapZodContext } from '~/types/ZSchemas/swap';
import { SwapSchema } from '~/constants/ZSchemas/swap';
import type { SwapPairProgress } from '~/types/Swap';

export const useSwapStore = defineStore(PiniaStoresId.SwapStore, () => {
  const walletStore = useWalletStore();
  const settingsStore = useSettingsStore();
  const { t } = useI18n();
  const toast = useToast();
  const alertBuilder = useAlertBuilder();

  const { assetsBalances } = storeToRefs(walletStore);
  const { uiMode } = storeToRefs(settingsStore);

  // Reactive state
  const pendingSwap = ref<SwapPairProgress>({
    payingAmount: undefined,
    receivingAmount: undefined,
    status: undefined,
    fee: 0,
    startingTimestamp: undefined,
    endingTimestamp: undefined
  });
  const swapPair = reactive({
    base: null as Asset | null,
    quote: null as Asset | null
  });
  const isFetchingEstimate = ref(false);

  // Form state
  const formState = reactive({
    selectedFromAssetBalance: null as MappedAssetBalance | null,
    selectedToAssetBalance: null as MappedAssetBalance | null,
    selectedFromAmount: 0,
    selectedToAmount: 0
  });

  const swapTime = computed(() => {
    return pendingSwap.value.endingTimestamp && pendingSwap.value.startingTimestamp
      ? (pendingSwap.value.endingTimestamp - pendingSwap.value.startingTimestamp) / 1000
      : 0;
  });

  // Computed properties
  const swapStatus = computed(() => {
    if (!pendingSwap) {
      return SwapProgressState.NULL;
    }
    switch (pendingSwap.value?.status) {
      case SwapStatus.OrderMatched:
      case SwapStatus.PayingInvoiceReceived:
      case SwapStatus.PaymentReceived:
      case SwapStatus.PaymentSent:
      case SwapStatus.ReceivingInvoiceCreated:
        return SwapProgressState.STARTED;
      case SwapStatus.SwapFailed:
        return SwapProgressState.FAILED;
      case SwapStatus.PaymentClaimed:
      case SwapStatus.PaymentClaimedByCounterparty:
        return SwapProgressState.COMPLETED;
      default:
        return SwapProgressState.NULL;
    }
  });

  const swapToAssetBalancesOptions = computed(() => {
    const selectedFrom = formState.selectedFromAssetBalance;
    return selectedFrom
      ? assetsBalances.value?.filter(asset => asset.asset.name !== selectedFrom.asset.name)
      : assetsBalances.value;
  });

  const computedSchemaObject = computed(() => {
    const context: SwapZodContext = {
      isEasyMode: uiMode.value === AppMode.EASY,
      fromAsset: formState.selectedFromAssetBalance,
      toAsset: formState.selectedToAssetBalance,
      t
    };
    return SwapSchema(context);
  });

  const watchedValues = computed(() => ({
    fromAssetBalance: formState.selectedFromAssetBalance,
    fromAmount: formState.selectedFromAmount,
    toAssetBalance: formState.selectedToAssetBalance
  }));

  // Utility functions
  function _validateForm () {
    if (!computedSchemaObject.value) {
      return false;
    }
    return validateForm<z.infer<typeof computedSchemaObject.value>>(
      formState,
      computedSchemaObject.value,
      t
    );
  }

  function resetFormState () {
    selectAssetBalances();
    formState.selectedFromAmount = 0;
    formState.selectedToAmount = 0;
    pendingSwap.value = {
      payingAmount: undefined,
      receivingAmount: undefined,
      status: undefined,
      startingTimestamp: undefined,
      endingTimestamp: undefined
    };
    swapPair.base = null;
    swapPair.quote = null;
  }

  function switchAssets () {
    const { selectedFromAssetBalance, selectedToAmount } = formState;
    formState.selectedFromAssetBalance = formState.selectedToAssetBalance;
    formState.selectedToAssetBalance = selectedFromAssetBalance;
    formState.selectedFromAmount = selectedToAmount;
  }

  function selectAssetBalances () {
    selectFromAssetBalance(assetsBalances.value);
    selectToAssetBalance(assetsBalances.value);
  }

  function setPendingSwap (event: DexPairEvent) {
    if (event.eventType === DexPairEventType.SwapProgress) {
      pendingSwap.value.status = event.swapProgress?.status;
      pendingSwap.value.payingAmount = event.swapProgress?.payingAmount;
      pendingSwap.value.receivingAmount = event.swapProgress?.receivingAmount;
      pendingSwap.value.endingTimestamp = convertSafelyBigIntToNumber(event.timestampMillis);
    }
    if (event.eventType === DexPairEventType.OrderMatched) {
      pendingSwap.value.startingTimestamp = convertSafelyBigIntToNumber(event.timestampMillis);
    }
  }

  function selectFromAssetBalance (newBalances: MappedAssetBalance[]) {
    formState.selectedFromAssetBalance = findAsset(newBalances, formState.selectedFromAssetBalance, 'ETH');
  }

  function selectToAssetBalance (newBalances: MappedAssetBalance[]) {
    formState.selectedToAssetBalance = findAsset(newBalances, formState.selectedToAssetBalance, 'BTC');
  }

  function findAsset (balances: MappedAssetBalance[], currentBalance: MappedAssetBalance | null, defaultSymbol: string) {
    return (
      balances.find(balance =>
        currentBalance
          ? balance.asset.symbol === currentBalance.asset.symbol &&
            balance.asset.network.symbol === currentBalance.asset.network.symbol
          : balance.asset.symbol === defaultSymbol
      ) || null
    );
  }

  async function setOrder () {
    if (!formState.selectedFromAssetBalance || !formState.selectedToAssetBalance || !swapPair.base || !swapPair.quote) {
      toast.add(alertBuilder.error({
        title: t('swap.order.errors.fetch.title'),
        description: t('Something went wrong while fetching market data. Please retry again or resync your wallet.')
      }));
      return;
    }

    const { selectedFromAssetBalance, selectedFromAmount } = formState;
    if (selectedFromAssetBalance.offchain_balance.free_local.asFloat() <= 0) {
      toast.add(alertBuilder.error({
        title: t('swap.order.errors.offchain-balance.title'),
        description: t('swap.order.errors.offchain-balance.description')
      }));
      return;
    }

    try {
      const fromAssetMatchesBaseAsset = selectedFromAssetBalance.asset.id === swapPair.base?.id;
      const marketOrder = fromAssetMatchesBaseAsset
        ? MarketOrderAmount.newBase(Amount.fromFloat(selectedFromAmount))
        : MarketOrderAmount.newQuote(Amount.fromFloat(selectedFromAmount));
      const order = fromAssetMatchesBaseAsset ? Order.newMarketSell(marketOrder) : Order.newMarketBuy(marketOrder);

      await createOrder(
        fromAssetMatchesBaseAsset ? selectedFromAssetBalance.asset : formState.selectedToAssetBalance?.asset,
        fromAssetMatchesBaseAsset ? formState.selectedToAssetBalance?.asset : selectedFromAssetBalance.asset,
        order
      );
    } catch (error) {
      toast.add(alertBuilder.error({
        title: t('swap.order.errors.swap.title'),
        description: t('swap.order.errors.swap.description')
      }));
      resetFormState();
      console.error('Error setting order:', error);
    }
  }

  watch(assetsBalances, selectAssetBalances, { immediate: true });

  watch(
    () => [watchedValues.value.fromAssetBalance, watchedValues.value.toAssetBalance],
    async ([fromAsset, toAsset]) => {
      if (!fromAsset || !toAsset) {
        return;
      }

      try {
        const pairs = await getInitializedPairs();
        const pairExists = pairs.some(([c1, c2]) =>
          (c1.id === fromAsset.asset.id && c2.id === toAsset.asset.id) ||
          (c1.id === toAsset.asset.id && c2.id === fromAsset.asset.id)
        );

        if (!pairExists) {
          await initPair(toAsset.asset, fromAsset.asset);
        }
      } catch (error) {
        toast.add(alertBuilder.error({
          title: t('swap.order.errors.init-pair.title'),
          description: t('swap.order.errors.init-pair.description')
        }));
        console.error('Error initializing pairs:', error);
      }
    }
  );

  watch(
    () => watchedValues.value.fromAmount,
    async (fromAmount) => {
      const { fromAssetBalance, toAssetBalance } = watchedValues.value;
      if (!fromAssetBalance || !toAssetBalance || fromAmount <= 0) {
        return;
      }

      try {
        isFetchingEstimate.value = true;
        const marketInfo = await getMarketInfo(fromAssetBalance.asset, toAssetBalance.asset);
        swapPair.base = marketInfo.base;
        swapPair.quote = marketInfo.quote;

        const fromAssetMatchesBaseAsset = fromAssetBalance.asset.id === swapPair.base?.id;

        let estimate;
        let fee = 0;
        let amount = 0;

        if (fromAssetMatchesBaseAsset) {
          estimate = await estimateMarketOrder(
            fromAssetBalance.asset,
            toAssetBalance.asset,
            OrderSide.Sell,
            MarketOrderAmount.newBase(Amount.fromFloat(fromAmount))
          );
          fee = estimate?.quoteFee?.asFloat() || 0;
          amount = estimate?.quoteAmount?.asFloat() || 0;
        } else {
          estimate = await estimateMarketOrder(
            toAssetBalance.asset,
            fromAssetBalance.asset,
            OrderSide.Buy,
            MarketOrderAmount.newQuote(Amount.fromFloat(fromAmount))
          );
          fee = estimate?.baseFee?.asFloat() || 0;
          amount = estimate?.baseAmount?.asFloat() || 0;
        }

        pendingSwap.value.fee = fee;
        formState.selectedToAmount = amount - fee;
      } catch (error) {
        toast.add(alertBuilder.error({
          title: t('swap.order.errors.estimate.title'),
          description: t('swap.order.errors.estimate.description')
        }));
        console.error('Error estimating market order:', error);
      } finally {
        isFetchingEstimate.value = false;
      }
    }
  );

  return {
    assetsBalances,
    setPendingSwap,
    swapToAssetBalancesOptions,
    formState,
    reset: resetFormState,
    switchAssets,
    setOrder,
    swapStatus,
    _validateForm,
    isFetchingEstimate,
    pendingSwap,
    computedSchemaObject,
    swapPair,
    swapTime
  };
});
