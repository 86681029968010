export enum PiniaStoresId {
    WalletActionsStore = 'walletActionsStore',
    ReceiveOffChainStore = 'receiveOffChainStore',
    ReceiveOnChainStore = 'receiveOnChainStore',
    LiquidityStore = 'liquidityStore',
    SendOnChainStore = 'sendOnChainStore',
    SendOffChainStore = 'sendOffChainStore',
    ChannelCloseStore = 'channelCloseStore',
    ChannelRentalStore = 'channelRentalStore',
    TransactionsHistoryStore = 'transactionsHistoryStore',
    OnChainTransactionStore = 'onChainTransactionStore',
    SettingsStore = 'settingsStore',
    CurrencyStore = 'currencyStore',
    ChannelsActionsStore = 'channelsActionsStore',
    ChannelsStore = 'channelsStore',
    WalletStore = 'walletStore',
    ChannelsTablesStore = 'channelsTablesStore',
TransferEvmStore = 'transferEvmStore',
SwapStore = 'SwapStore',
TransferLightningBackOnChainStore = 'transferLightningBackOnChainStore',
    TransferLightningStoreToOffChainStore = 'transferLightningStoreToOffChainStore',
    PeerStore = 'peerStore',
    HydraNodeStore = 'hydraNodeStore',
}
