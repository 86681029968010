<script lang="ts" setup>

import ModalHeader from '~/components/pro/templates/Modals/ModalHeader.vue';

interface Props {
  title: string;
  subtitle?: string;
}

defineProps<Props>();
defineEmits<{
  close: [];
}>();

const isOpen = defineModel('isOpen', {
  default: false
});
</script>
<template>
  <UModal
    v-model="isOpen"
    :ui="{ width: 'sm:max-w-xl' }"
    prevent-close
    @close="$emit('close')"
  >
    <UCard :ui="{ divide: 'divide-y divide-gray-100 dark:divide-gray-800' }">
      <template #header>
        <ModalHeader
          :title="title"
          :subtitle="subtitle"
          closable
          @click="$emit('close')"
        />
      </template>

      <!-- Main Content -->
      <div
        v-if="$slots.steps"
        class="u-border-200 mb-5 border-b pb-5 sm:mb-6 sm:pb-6"
      >
        <div class="flex justify-center">
          <slot name="steps" />
        </div>
      </div>
      <section>
        <slot />
      </section>

      <template #footer>
        <slot name="footer" />
      </template>
    </UCard>
  </UModal>
</template>
