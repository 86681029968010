<script setup lang="ts">
import SlideoverWrapper from '~/components/easy/molecules/wrapper/SlideoverWrapper.vue';
import NetworkList from '~/components/easy/molecules/list/NetworkList.vue';
import supportedNetworks from '~/config/supported-networks.json';
import type { NetworkSelectionOption } from '~/types/filters';
import EasyNetwork from '~/components/easy/molecules/EasyNetwork.vue';

interface Props {
  side?: 'bottom' | 'left' | 'right' | 'top';
  title?: string;
  options?: NetworkSelectionOption[]
}

const props = withDefaults(defineProps<Props>(), {
  side: 'bottom',
  title: 'Networks'
});

const selectedFilterNetworks = ref<NetworkSelectionOption[]>([]);

const DEFAULT_SELECTED: NetworkSelectionOption = {
  label: 'All Networks',
  value: 'all',
  symbol: '',
  logoUrl: ''
};

const emit = defineEmits<{
  'change:value': [value: NetworkSelectionOption]
}>();

const isSlideoverOpen = ref(false);

const networkOptions = computed(() => {
  return props.options && props.options.length > 0
    ? props.options
    : [DEFAULT_SELECTED, ...supportedNetworks];
});

const selectedValue = ref<string>(DEFAULT_SELECTED.value);

const selectedNetwork = computed(() => {
  return selectedValue.value ? networkOptions.value.find((o: { value: string; }) => o.value === selectedValue.value) : DEFAULT_SELECTED;
});

function resetSelection () {
  selectedFilterNetworks.value = [];
  emitSelectedNetwork(DEFAULT_SELECTED);
}

function handleNetworkChange (option: NetworkSelectionOption) {
  selectedFilterNetworks.value = option.value === 'all' ? [] : [option];
  isSlideoverOpen.value = false;
  emitSelectedNetwork(option);
}

function emitSelectedNetwork (option: NetworkSelectionOption) {
  emit('change:value', option);
}

watchEffect(() => {
  if (selectedFilterNetworks.value.length === 0) {
    selectedValue.value = DEFAULT_SELECTED.value;
  }
});

</script>

<template>
  <div class="flex items-center justify-between gap-x-4">
    <span class="text-gray-secondary text-sm">{{ $t('easy-wallet-page.modals.network.title') }}</span>
    <UButton color="gray">
      <EasyNetwork v-bind="selectedNetwork" @click="isSlideoverOpen = true" />
    </UButton>
  </div>
  <SlideoverWrapper v-model="isSlideoverOpen" :title="title" :side="side">
    <template #right>
      <UButton variant="link" @click="resetSelection">
        {{ $t('generics.modals.reset') }}
      </UButton>
    </template>
    <template #body>
      <NetworkList
        v-model="selectedValue"
        :networks="networkOptions"
        @change:option="handleNetworkChange"
      />
    </template>

    <template #footer>
      <UButton variant="solid" block size="lg" @click="isSlideoverOpen = false">
        <span class="text-xl font-medium">{{ $t('generics.modals.save') }}</span>
      </UButton>
    </template>
  </SlideoverWrapper>
</template>
