export enum WalletActions {
  SEND = 'SEND',
  RECEIVE = 'RECEIVE',
  TRANSFER = 'TRANSFER',
  RENT = 'RENT',
  CLOSE = 'CLOSE'
}

export enum TransferSteps {
  Layer = 'LAYER',
  Amount = 'AMOUNT',
  Summary = 'SUMMARY',
  Success = 'SUCCESS'
}

export enum ReceiveOnChainSteps {
  Layer = 'LAYER',
  Address = 'ADDRESS'
}

export enum ReceiveOffChainSteps {
  Layer = 'LAYER',
  Method = 'METHOD',
  Address = 'ADDRESS',
  Details = 'DETAILS',
  Invoice = 'INVOICE'
}

export enum LiquiditySteps {
  ASSETS = 'ASSETS',
  RANGE = 'RANGE',
  AMOUNT = 'AMOUNT'
}

export enum SendOnChainSteps {
  Layer = 'LAYER',
  Address = 'ADDRESS',
  Amount = 'AMOUNT',
  Summary = 'SUMMARY',
  Success = 'SUCCESS'
}

export enum SendOffChainSteps {
  Layer = 'LAYER',
  Method = 'METHOD',
  Details = 'DETAILS',
  Summary = 'SUMMARY',
  Success = 'SUCCESS'
}
