<script lang="ts" setup>
import { useWindowSize } from '@vueuse/core';
import { AssetBalance } from 'hydra-node';
import NetworkSelection from '~/components/easy/molecules/NetworkSelection.vue';
import SlideoverWrapper from '~/components/easy/molecules/wrapper/SlideoverWrapper.vue';
import EasyToken from '~/components/easy/molecules/EasyToken.vue';
import TokenSkeleton from '~/components/shared/templates/Skeletons/TokenSkeleton.vue';
import EasyTokenList from '~/components/easy/molecules/list/EasyTokenList.vue';
import type { NetworkSelectionOption } from '~/types/filters';
import { Breakpoints } from '~/config';

interface Props {
  options: AssetBalance[];
  size: 'sm' | 'md' | 'lg';
}

const props = defineProps<Props>();

const { width } = useWindowSize();

const assetBalance = defineModel<AssetBalance>('balance');

const isOpen = ref(false);

const selectedNetwork = ref<NetworkSelectionOption | null>();

const filteredAssetBalances = computed(() => {
  if (!selectedNetwork.value || selectedNetwork.value.value === 'all') {
    return props.options;
  }
  return props.options.filter(assetBalance => assetBalance.asset.network.symbol === selectedNetwork.value?.symbol);
});

const isMobile = computed(() => width.value < Breakpoints.lg);

const slideoverSide = computed(() => {
  return isMobile.value ? 'bottom' : 'right';
});

function handleAssetChange (selectedAssetBalance: AssetBalance) {
  assetBalance.value = selectedAssetBalance;
  isOpen.value = false;
}

function handleNetworkChange (selectedNetworkOption: NetworkSelectionOption) {
  selectedNetwork.value = selectedNetworkOption;
}

function resetSelection () {
  assetBalance.value = undefined;
  isOpen.value = false;
};

</script>

<template>
  <div class="u-border-gray-200 u-bg-main flex items-center rounded border p-2">
    <div class="flex w-full">
      <!-- Button to open the slideover -->
      <UButton variant="ghost" class="justify-start" block @click="isOpen = true">
        <EasyToken
          v-if="assetBalance?.asset"
          :name="assetBalance?.asset?.name"
          :symbol="assetBalance?.asset?.symbol"
          :logo-url="assetBalance?.asset?.logoUrl"
          :size="size"
          :network="assetBalance?.asset?.network"
        />
        <TokenSkeleton v-else />
        <template #trailing>
          <UIcon name="i-heroicons-chevron-down" class="text-gray-secondary size-5" />
        </template>
      </UButton>

      <!-- Slideover Background -->
      <SlideoverWrapper v-model="isOpen" title="Tokens" :side="slideoverSide">
        <template #right>
          <UButton variant="link" @click="resetSelection">
            {{ $t('generics.modals.reset') }}
          </UButton>
        </template>
        <template #body>
          <div class="flex flex-col gap-y-3">
            <NetworkSelection :side="slideoverSide" @change:value="handleNetworkChange" />
            <EasyTokenList :asset-balances="filteredAssetBalances" @change:value="handleAssetChange" />
          </div>
        </template>

        <template #footer>
          <UButton variant="solid" block size="lg" @click="isOpen = false">
            <span class="text-xl font-medium">{{ $t('generics.modals.save') }}</span>
          </UButton>
        </template>
      </SlideoverWrapper>
    </div>
  </div>
</template>
