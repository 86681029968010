<script lang="ts" setup>
import { ref, computed } from 'vue';

interface Props {
  allowNegative?: boolean
  unit?: string
  inputClass?: string
  variant?: 'outline' | 'none'
}

const props = withDefaults(defineProps<Props>(), {
  allowNegative: false,
  variant: 'outline'
});

const emit = defineEmits<{
  'update:modelValue': [value: number]
}>();
/**
 * As we do not update the display value when v-model changes to avoid
 * unwanted updates on the input field. We shall expose a function
 * to update the display value from outside the component.
 * When we want to force an update, we can use this function.
 * @param value
 */
function setValue (value:string|number) {
  handleNewValue(String(value));
}

defineExpose({ setValue });

const [_modelValue, modifiers] = defineModel<string|number>();
const displayValue = ref(_modelValue.value?.toString());

const modelValue = computed({
  get: () => _modelValue.value,
  set: (value: number) => emit('update:modelValue', value)
});

function parseNumber (value: string): number {
  // Remove all whitespace
  value = String(value).replace(/\s/g, '');

  // Check for negative value
  const isNegative = value.indexOf('-') === 0;
  if (isNegative && !props.allowNegative) {
    return NaN;
  }

  value = value.replace(/^-/, '');

  const validCharsRegex = /^[0-9', .\sEe-]+$/;
  if (!validCharsRegex.test(value)) {
    return NaN;
  }

  // Check for multiple dots or commas
  const dotCount = (value.match(/\./g) || []).length;

  if (dotCount > 1) {
    return NaN;
  }

  if (value?.startsWith('0,') || value?.startsWith(',')) {
    return NaN;
  }
  // Find the decimal separator (last instance of . or ,)
  const decimalIndex = Math.max(value.lastIndexOf('.'));

  const integerPart = decimalIndex !== -1 ? value.slice(0, decimalIndex) : value;
  const fractionalPart = decimalIndex !== -1 ? value.slice(decimalIndex + 1) : '';

  // Combine the parts
  let result = parseFloat(`${integerPart}.${fractionalPart}`);

  // Apply the negative sign if necessary
  if (isNegative) {
    result *= -1;
  }

  return isNaN(result) ? NaN : result;
}

function handleNewValue (value: string) {
  displayValue.value = value;

  let parsedValue = parseNumber(value);

  // Handle negative values
  if (!props.allowNegative && parsedValue < 0) {
    parsedValue = Math.abs(parsedValue);
  }

  modelValue.value = parsedValue;
}

function update (evt: Event | string) {
  if (!modifiers?.lazy) {
    handleNewValue((evt.target as HTMLInputElement).value);
  }
}

function change (value: string) {
  if (modifiers?.lazy) {
    handleNewValue(value);
  }
}
</script>

<template>
  <UInput
    :model-value="displayValue"
    :class="inputClass"
    :variant="variant"
    @input="update"
    @change="change"
  >
    <template v-if="!!$slots.leading && !$attrs.loading" #leading>
      <slot name="leading" />
    </template>
    <template v-if="(!!$slots.trailing || !!unit) && !$attrs.loading" #trailing>
      <slot name="trailing">
        {{ unit }}
      </slot>
    </template>
  </UInput>
</template>
