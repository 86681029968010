import { AssetBalance, CurrencyInfo } from 'hydra-node';

export function getAssetBalanceId (assetBalance?: AssetBalance) {
  return assetBalance?.asset?.asEvm()?.id?.toString() || assetBalance?.asset?.asBitcoin()?.id?.toString() || '';
}

export function compareCurrencyInfo (currencyInfo1: CurrencyInfo | undefined | null, currencyInfo2: CurrencyInfo | undefined | null) {
  if (!currencyInfo1 || !currencyInfo2) {
    return false;
  }
  return currencyInfo1?.assetId === currencyInfo2?.assetId && currencyInfo1?.networkId === currencyInfo2?.networkId && currencyInfo1?.protocol === currencyInfo2?.protocol;
}
