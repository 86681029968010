<template>
  <div class="flex items-center space-x-2">
    <USkeleton
      class="size-10"
      :ui="{ rounded: 'rounded-full' }"
    />
    <div class="space-y-3">
      <USkeleton class="h-3 w-32" />
      <USkeleton class="h-3 w-20" />
    </div>
  </div>
</template>
