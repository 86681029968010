<script setup lang="ts">

interface Props {
  side?: 'bottom' | 'left' | 'right' | 'top';
  title: string;
}

withDefaults(defineProps<Props>(), {
  side: 'bottom'
});

const isSlideoverOpen = defineModel();

</script>

<template>
  <USlideover v-model="isSlideoverOpen" :side="side" :ui="{height: 'h-screen max-h-112'}">
    <UCard class="flex flex-1 flex-col justify-between" :ui="{ body: { base: 'flex-1' }}">
      <!-- Header Slot -->
      <template #header>
        <div class="flex items-center justify-between">
          <div class="flex items-center">
            <UButton
              color="gray"
              variant="ghost"
              icon="i-heroicons-arrow-left"
              size="lg"
              class="-my-1"
              @click="isSlideoverOpen = false"
            />
            <span class="text-gray-primary text-xl font-medium">
              {{ title }}
            </span>
          </div>
          <slot name="right" />
        </div>
      </template>

      <!-- Main Body Slot -->
      <div class="flex-1 overflow-y-auto">
        <slot name="body" />
      </div>
      <!-- Footer Slot -->
      <template #footer>
        <slot name="footer" />
      </template>
    </UCard>
  </USlideover>
</template>
