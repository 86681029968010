import type { Button, NotificationAction } from '#ui/types';
import type { Alert } from '~/types';

const baseActionArgs = {
  padded: true,
  size: 'xs',
  disabled: false
};

const closeButton: Button = {
  icon: 'i-heroicons-x-close',
  color: 'gray',
  variant: 'link',
  padded: false
};

export function useAlertBuilder () {
  const { t } = useI18n();

  function primaryAndSecondaryActions (actions: NotificationAction[]) {
    return actions.map((action, index) => {
      return {
        color: index % 2 === 0 ? 'primary' : 'gray',
        variant: 'link',
        ...baseActionArgs,
        ...action
      };
    }) as NotificationAction[];
  }

  function supportContact (action?: NotificationAction): NotificationAction {
    return {
      label: t('generics.errors.action.contact-support'),
      to: 'mailto:info@hydranet.ai',
      color: 'gray',
      padded: false,
      variant: 'link',
      ...action
    } as NotificationAction;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars,n/handle-callback-err
  function buildFromServerError (err: unknown, canClose = false, support = true, notification: Partial<Alert> = {}) {
    // TODO once we are able to extract meaningful error message from server response
  }

  function success (notification: Partial<Alert> = {}, canClose = false): Alert {
    const title = notification.title || t('generics.success');

    return {
      id: title,
      variant: 'subtle',
      actions: [],
      color: 'green',
      icon: 'i-heroicons-check-circle',
      closeButton: canClose ? closeButton : undefined,
      ...notification,
      title
    } as Alert;
  }

  function error (notification: Partial<Alert> = {}, canClose = false, support = true): Alert {
    const title = notification.title || t('generics.errors');

    const alert = {
      id: title,
      variant: 'subtle',
      actions: [],
      color: 'red',
      icon: 'i-heroicons-exclamation-circle',
      closeButton: canClose ? closeButton : undefined,
      ...notification,
      title
    };

    if (support) { alert.actions.push(supportContact() as never); }

    return alert as Alert;
  }

  function info (notification: Partial<Alert> = {}, canClose = false, support = false) {
    const alert = {
      id: notification.title,
      variant: 'subtle',
      actions: [],
      color: 'blue',
      icon: 'i-heroicons-info-circle',
      closeButton: canClose ? closeButton : undefined,
      ...notification
    };
    if (support) { alert.actions.push(supportContact() as never); }

    return alert as Alert;
  }

  function warning (notification: Partial<Alert>, canClose = false) {
    return {
      id: notification.title,
      variant: 'subtle',
      icon: 'i-heroicons-exclamation-triangle',
      color: 'yellow',
      closeButton: canClose ? closeButton : undefined,
      actions: [],
      ...notification
    } as Partial<Alert>;
  }
  return {
    primaryAndSecondaryActions,
    success,
    error,
    info,
    warning
  };
}
