export const PRO_APP_ROUTES = {
  wallet: {
    path: '/',
    name: 'wallet'
  },
  channels: {
    path: '/channels',
    name: 'channels',
    children: {
      peer: {
        network: {
          path: '/channels/:peer/:network/:id',
          name: 'channels-peer-network-id'
        },
        asset: {
          path: '/channels/:peer/:network/:id/:symbol',
          name: 'channels-peer-network-id-symbol'
        }
      }
    }
  },
  swap: {
    path: '/swap',
    name: 'swap'
  },
  transactions: {
    path: '/transactions',
    name: 'transactions',
    children: {
      details: {
        path: '/transactions/details/:id',
        name: 'transactions-details-id'
      }
    }
  },
  liquidity: {
    path: '/liquidity',
    name: 'liquidity'
  }
};
export const EASY_APP_ROUTES = {
  unlock: {
    path: '/unlock',
    name: 'unlock'
  },
  setup: {
    path: '/setup',
    name: 'setup',
    children: {
      create: {
        path: '/setup/create',
        name: 'create',
        children: {
          confirm: {
            path: '/setup/create/confirm',
            name: 'confirm'
          },
          verify: {
            path: '/setup/create/verify',
            name: 'verify'
          }
        }
      },
      import: {
        path: '/setup/import',
        name: 'import'
      },
      password: {
        path: '/setup/password',
        name: 'password'
      }
    }
  },
  account: {
    path: '/account',
    name: 'account',
    children: {
      forgotPassword: {
        path: '/account/forgot-password',
        name: 'forgotPassword'
      },
      resetPassword: {
        path: '/account/reset-password',
        name: 'resetPassword'
      }
    }

  },
  home: {
    path: '/',
    name: 'home'
  },
  receive: {
    path: '/receive',
    name: 'receive'
  },
  send: {
    path: '/send',
    name: 'send'
  },
  swap: {
    path: '/swap',
    name: 'swap'
  },
  transactions: {
    path: '/transactions',
    name: 'transactions'
  }
};
