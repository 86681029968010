import revive_payload_client_mnnwFe1l8s from "/opt/build/repo/node_modules/.pnpm/nuxt@3.15.0_@types+node@18.19.68_eslint@8.57.1_rollup@4.29.1_sass@1.83.0_stylelint@15.11.0_ty_ne5rlqmexdp2rvsgkwamgdodje/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_QugKiT2fxv from "/opt/build/repo/node_modules/.pnpm/nuxt@3.15.0_@types+node@18.19.68_eslint@8.57.1_rollup@4.29.1_sass@1.83.0_stylelint@15.11.0_ty_ne5rlqmexdp2rvsgkwamgdodje/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_ecujNZc0cW from "/opt/build/repo/node_modules/.pnpm/nuxt@3.15.0_@types+node@18.19.68_eslint@8.57.1_rollup@4.29.1_sass@1.83.0_stylelint@15.11.0_ty_ne5rlqmexdp2rvsgkwamgdodje/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_XBBOPkWnxY from "/opt/build/repo/node_modules/.pnpm/nuxt-site-config@2.2.21_rollup@4.29.1_vite@5.4.11_vue@3.5.13/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_ERGbkhQYnL from "/opt/build/repo/node_modules/.pnpm/nuxt@3.15.0_@types+node@18.19.68_eslint@8.57.1_rollup@4.29.1_sass@1.83.0_stylelint@15.11.0_ty_ne5rlqmexdp2rvsgkwamgdodje/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_oPbLd7tw7g from "/opt/build/repo/node_modules/.pnpm/nuxt@3.15.0_@types+node@18.19.68_eslint@8.57.1_rollup@4.29.1_sass@1.83.0_stylelint@15.11.0_ty_ne5rlqmexdp2rvsgkwamgdodje/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_n8mNSn5UHI from "/opt/build/repo/node_modules/.pnpm/nuxt@3.15.0_@types+node@18.19.68_eslint@8.57.1_rollup@4.29.1_sass@1.83.0_stylelint@15.11.0_ty_ne5rlqmexdp2rvsgkwamgdodje/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_X19LOjbHCf from "/opt/build/repo/node_modules/.pnpm/nuxt@3.15.0_@types+node@18.19.68_eslint@8.57.1_rollup@4.29.1_sass@1.83.0_stylelint@15.11.0_ty_ne5rlqmexdp2rvsgkwamgdodje/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_ww1zCca2Gg from "/opt/build/repo/node_modules/.pnpm/@pinia+nuxt@0.5.5_rollup@4.29.1_typescript@5.7.2_vue@3.5.13/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/build/repo/.nuxt/components.plugin.mjs";
import prefetch_client_DunQ8HWtF4 from "/opt/build/repo/node_modules/.pnpm/nuxt@3.15.0_@types+node@18.19.68_eslint@8.57.1_rollup@4.29.1_sass@1.83.0_stylelint@15.11.0_ty_ne5rlqmexdp2rvsgkwamgdodje/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import titles_5uQ9pHbESC from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.10_h3@1.13.0_rollup@4.29.1_unhead@1.11.14_vite@5.4.11_vue@3.5.13/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.mjs";
import defaults_7VCEOPqqRV from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.10_h3@1.13.0_rollup@4.29.1_unhead@1.11.14_vite@5.4.11_vue@3.5.13/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaults.mjs";
import siteConfig_ztCm94anXh from "/opt/build/repo/node_modules/.pnpm/nuxt-seo-experiments@4.0.0-rc.5_rollup@4.29.1_vite@5.4.11_vue@3.5.13/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.mjs";
import inferSeoMetaPlugin_lSw9jhShHr from "/opt/build/repo/node_modules/.pnpm/nuxt-seo-experiments@4.0.0-rc.5_rollup@4.29.1_vite@5.4.11_vue@3.5.13/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.mjs";
import i18n_d3ePdFP2VF from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+i18n@8.3.1_rollup@4.29.1_vue@3.5.13/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import slideovers_0zGVY0PR8k from "/opt/build/repo/node_modules/.pnpm/@nuxt+ui@2.20.0_qrcode@1.5.4_rollup@4.29.1_typescript@5.7.2_vite@5.4.11_vue@3.5.13/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_kuLdJq0Kbe from "/opt/build/repo/node_modules/.pnpm/@nuxt+ui@2.20.0_qrcode@1.5.4_rollup@4.29.1_typescript@5.7.2_vite@5.4.11_vue@3.5.13/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_F7dqkGuIKY from "/opt/build/repo/node_modules/.pnpm/@nuxt+ui@2.20.0_qrcode@1.5.4_rollup@4.29.1_typescript@5.7.2_vite@5.4.11_vue@3.5.13/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_4EIcKyYiFC from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2_rollup@4.29.1/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_9QsY2Whpu0 from "/opt/build/repo/node_modules/.pnpm/@nuxt+icon@1.10.3_rollup@4.29.1_vite@5.4.11_vue@3.5.13/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import scrollbars_client_IFDHhs33WT from "/opt/build/repo/node_modules/.pnpm/@nuxt+ui-pro@1.6.0_qrcode@1.5.4_rollup@4.29.1_typescript@5.7.2_vite@5.4.11_vue@3.5.13/node_modules/@nuxt/ui-pro/modules/pro/runtime/plugins/scrollbars.client.ts";
import plugin_XAkhz2OkXs from "/opt/build/repo/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.1_@pinia+nuxt@0.5.5_pinia@2.3.0_rollup@4.29.1/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import presets_CMssLpCjGU from "/opt/build/repo/node_modules/.pnpm/@nuxt+ui-pro@1.6.0_qrcode@1.5.4_rollup@4.29.1_typescript@5.7.2_vite@5.4.11_vue@3.5.13/node_modules/@nuxt/ui-pro/plugins/presets.ts";
import variables_TAqq3ZGsS9 from "/opt/build/repo/node_modules/.pnpm/@nuxt+ui-pro@1.6.0_qrcode@1.5.4_rollup@4.29.1_typescript@5.7.2_vite@5.4.11_vue@3.5.13/node_modules/@nuxt/ui-pro/plugins/variables.ts";
import i18n_VfGcjrvSkj from "/opt/build/repo/plugins/i18n.ts";
import ui_rjNXKDZLQ9 from "/opt/build/repo/plugins/ui.ts";
export default [
  revive_payload_client_mnnwFe1l8s,
  unhead_QugKiT2fxv,
  router_ecujNZc0cW,
  _0_siteConfig_XBBOPkWnxY,
  payload_client_ERGbkhQYnL,
  navigation_repaint_client_oPbLd7tw7g,
  check_outdated_build_client_n8mNSn5UHI,
  chunk_reload_client_X19LOjbHCf,
  plugin_vue3_ww1zCca2Gg,
  components_plugin_KR1HBZs4kY,
  prefetch_client_DunQ8HWtF4,
  titles_5uQ9pHbESC,
  defaults_7VCEOPqqRV,
  siteConfig_ztCm94anXh,
  inferSeoMetaPlugin_lSw9jhShHr,
  i18n_d3ePdFP2VF,
  slideovers_0zGVY0PR8k,
  modals_kuLdJq0Kbe,
  colors_F7dqkGuIKY,
  plugin_client_4EIcKyYiFC,
  plugin_9QsY2Whpu0,
  scrollbars_client_IFDHhs33WT,
  plugin_XAkhz2OkXs,
  presets_CMssLpCjGU,
  variables_TAqq3ZGsS9,
  i18n_VfGcjrvSkj,
  ui_rjNXKDZLQ9
]