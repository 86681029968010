<script lang="ts" setup>
import { ref } from 'vue';
import { storeToRefs } from 'pinia';
import Logo from '~/components/pro/atoms/Logo.vue';
import Sidebar from '~/components/pro/templates/Sidebar.vue';
import ThemeToggle from '~/components/pro/organisms/ThemeToggle.vue';
import { useHydraNodeStore } from '~/stores/hydra-node';
import HydraNodeErrorInstanceSpy from '~/components/pro/atoms/HydraNodeErrorInstanceSpy.vue';
import NodeDataDisplay from '~/components/pro/atoms/NodeDataDisplay.vue';
import HydraNodeSyncingModal from '~/components/pro/templates/Modals/HydraNodeSyncingModal.vue';
import { PRO_APP_ROUTES } from '~/constants';
import MnemonicModal from '~/components/pro/templates/Modals/MnemonicModal.vue';
import TableWithSidebarErrorPlaceholder from '~/components/pro/templates/TableWithSidebarErrorPlaceholder.vue';
import SettingsModal from '~/components/pro/templates/Modals/SettingsModal.vue';
const { t } = useI18n();
const hydraNodeStore = useHydraNodeStore();
const { mnemonic, isPackageInitialized, isNodeFullySynced } =
  storeToRefs(hydraNodeStore);

const settingsModal = useModal();
const isMobileSidebarOpen = ref(false);

watchEffect(() => {
  if (settingsModal.isOpen.value && isMobileSidebarOpen.value) {
    isMobileSidebarOpen.value = false;
  }
});

function openSettingsModal () {
  settingsModal.open(SettingsModal, { close: settingsModal.close });
}

const navigation = [
  {
    label: t('navigation.wallet'),
    icon: 'i-heroicons-home',
    to: PRO_APP_ROUTES.wallet.path
  },
  {
    label: t('navigation.channels'),
    icon: 'i-heroicons-bolt',
    to: PRO_APP_ROUTES.channels.path
  },
  {
    label: t('navigation.swap'),
    icon: 'i-heroicons-arrows-right-left',
    to: PRO_APP_ROUTES.swap.path
  },
  {
    label: t('navigation.transactions'),
    icon: 'i-heroicons-rectangle-stack',
    to: PRO_APP_ROUTES.transactions.path
  }/* ,
  {
    label: t('navigation.liquidity'),
    icon: 'i-heroicons-chart-bar',
    to: PRO_APP_ROUTES.liquidity.path
  } */
];

const bottomNavigation = [
  {
    label: t('navigation.settings'),
    icon: 'i-heroicons-cog-8-tooth',
    click: openSettingsModal
  }
];

const logError = e => console.debug('Error captured:', e);
</script>
<template>
  <div class="flex min-h-screen flex-row">
    <Sidebar
      v-model="isMobileSidebarOpen"
      class="shrink-0"
    >
      <template #logo>
        <div class="flex h-8 w-full items-center">
          <Logo class="text-primary-500 h-8 w-28 lg:h-auto" />
        </div>
      </template>
      <template #top-navigation>
        <div class="flex flex-1 flex-col lg:flex-row lg:items-start">
          <UVerticalNavigation
            :links="navigation"
            class="w-full"
          />
        </div>
      </template>
      <template #node-information>
        <div class="pb-4">
          <NodeDataDisplay />
        </div>
      </template>
      <template #bottom-navigation>
        <div class="flex flex-1 flex-col lg:flex-row lg:items-start">
          <UVerticalNavigation
            :links="bottomNavigation"
            class="w-full"
          />
        </div>
      </template>
    </Sidebar>

    <div
      class="flex flex-1 flex-col overflow-x-hidden"
      :class="{
        'lg:max-w-[calc(100%-18rem)] xl:max-w-[calc(100%-21rem)]':
          $attrs.sidebar
      }"
    >
      <div class="inset-x-0 top-0 z-40 lg:absolute lg:px-8">
        <div
          class="u-border-gray-200 my-auto flex h-16 items-center gap-x-4 border-b px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:border-0 lg:px-0 lg:shadow-none"
        >
          <div
            class="flex w-full justify-end gap-x-4 sm:gap-x-6 lg:ml-auto lg:w-auto"
          >
            <ThemeToggle />
            <UButton
              variant="ghost"
              color="gray"
              icon="i-heroicons-cog-8-tooth"
              @click="openSettingsModal"
            />
          </div>
          <button
            type="button"
            class="u-text-gray-900 flex items-center lg:hidden"
            @click="isMobileSidebarOpen = true"
          >
            <UIcon
              name="i-heroicons-bars-3"
              class="size-6"
              aria-hidden="true"
            />
          </button>
        </div>
      </div>

      <main class="flex flex-1 flex-col self-stretch">
        <section
          class="app-container from-primary-100 to-bg-primary dark:from-primary-900 dark:to-bg-secondary relative flex flex-col justify-end bg-gradient-to-l to-90% py-4 md:pb-6 md:pt-10 lg:pt-20"
        >
          <h1
            class="u-text-gray-900 text-xl font-semibold xs:text-2xl md:text-3xl"
            :class="{
              'mb-2': !!$slots.breadcrumb
            }"
          >
            <slot name="title">
              <template v-if="$attrs.title">
                {{ $attrs.title }}
              </template>
              <USkeleton
                v-else
                class="h-10 w-80"
              />
            </slot>
          </h1>
          <slot
            v-if="$slots.breadcrumb"
            name="breadcrumb"
          >
            <USkeleton class="h-10 w-80" />
          </slot>
        </section>
        <div class="app-container mt-6 h-full pb-6">
          <NuxtErrorBoundary @error="logError">
            <template v-if="!isNodeFullySynced">
              <slot name="skeleton" />

              <div v-if="!mnemonic">
                <MnemonicModal v-if="isPackageInitialized" />
              </div>
              <HydraNodeSyncingModal v-else />
            </template>
            <template v-else>
              <slot />
            </template>

            <HydraNodeErrorInstanceSpy />
            <template #error="{ error }">
              <slot
                name="error"
                :message="error"
                :synced="isNodeFullySynced"
              >
                <template v-if="isNodeFullySynced">
                  <div class="space-y-2 text-center">
                    <h1 class="text-2xl font-medium">
                      {{ $t('errors.generic.title') }}
                    </h1>
                    <h3 class="text-lg font-bold">
                      {{ $t('errors.generic.message') }}
                    </h3>
                    <p class="u-bg-gray-100 p-2 text-sm italic">
                      {{ error }}
                    </p>
                  </div>
                </template>
                <template v-else>
                  <TableWithSidebarErrorPlaceholder />
                </template>
              </slot>
            </template>
          </NuxtErrorBoundary>
        </div>
      </main>
    </div>
  </div>
</template>
